<template>
  <v-container fluid>
    <FacilitiesTabs
      :configurations="configurations"
      :show-add-category="true"
      :venue-service-id="venueServiceId"
      :venue-services="venueServices"
      @serviceChange="serviceChange"
      @showCategoryDialog="(value) => (categoryDialog = value)"
      @update:venueServiceId="(value) => (venueServiceId = value)"
    />

    <v-divider
      class="mt-4"
      style="border-color: rgba(17, 42, 70, 0.14) !important"
    />

    <v-row class="mt-8" dense>
      <v-col cols="12">
        <div class="d-flex gap-x-5">
          <v-btn
            v-for="category in productCategories"
            :key="`tb_${category.category_id}`"
            :class="{
              active_category: selectedCategory === category.category_id,
            }"
            class="text-capitalize"
            outlined
            style="
              border: 1px solid rgba(17, 42, 70, 0.1);
              background-color: #ffffff;
            "
            @click="selectedCategory = category.category_id"
          >
            {{ category.name }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <template v-for="(category, ci) in productCategories">
        <template v-if="selectedCategory == category.category_id">
          <v-col
            v-for="(product, pi) in category.products"
            :key="product.id"
            lg="4"
            md="4"
            xl="3"
          >
            <product-widget
              :key="pi"
              :cindex="ci"
              :index="pi"
              :service="serviceName"
              v-bind="product"
              @delete="deleteProduct"
              @edit="editProduct"
            ></product-widget>
          </v-col>
        </template>
      </template>
    </v-row>

    <v-row v-if="productCategories.length" class="mt-2" dense>
      <v-col>
        <v-btn
          class="shadow-0"
          color="teal-color"
          dark
          height="48"
          @click.stop="addProduct(selectedCategory)"
        >
          +Add Product
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="1 == 2">
      <v-col>
        <v-expansion-panels
          :value="openTabs"
          accordion
          flat
          hover
          light
          multiple
          tile
        >
          <template v-for="(category, ci) in productCategories">
            <v-expansion-panel v-if="category.name" :key="`c_${ci}`">
              <v-expansion-panel-header>
                <span class="month_names">{{ category.name }}</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    v-for="(product, pi) in category.products"
                    :key="product.id"
                    lg="3"
                  >
                    <product-widget
                      :key="pi"
                      :cindex="ci"
                      :index="pi"
                      :service="serviceName"
                      v-bind="product"
                      @delete="deleteProduct"
                      @edit="editProduct"
                    ></product-widget>
                  </v-col>
                  <v-col md="3">
                    <v-card
                      :style="cardStyle"
                      class="pa-4 d-flex align-center justify-center"
                      height="210"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="#00b0af"
                            dark
                            elevation="0"
                            fab
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="addProduct(ci)"
                          >
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                        </template>
                        Add Product
                      </v-tooltip>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <confirm-model
      v-bind="confirmModel"
      @close="confirmModel.id = null"
      @confirm="confirmActions"
    ></confirm-model>
    <product-creation
      v-if="productDialog"
      :is-enable-add-product="productDialog"
      :isEnableDctErp="isEnableDctErp"
      :projectNumber="projectNumber"
      :transactionType="transactionType"
      v-bind="productData"
      @close="productClose"
      @deleteProduct="deleteProduct"
      @save="productSave"
    />
    <product-category
      v-if="categoryDialog"
      :is-enable-product-category="categoryDialog"
      :product-categories="productCategories"
      :venue-service-id="venueServiceId"
      @close="(categoryDialog = false), getProductAndCategory()"
      @delete="deleteCategory"
      @save="getProductAndCategory(), (categoryDialog = false)"
    />
  </v-container>
</template>

<script>
import ProductWidget from "@/components/Product/ProductWidget";
import ProductCreation from "@/components/Facility/ProductCreation";
import productCategory from "@/components/Facility/ProductCategory";
import constants from "@/utils/constants";
import FacilitiesTabs from "@/views/Facility/FacilitiesTabs.vue";

export default {
  components: {
    FacilitiesTabs,
    ProductWidget,
    ProductCreation,
    productCategory,
  },

  data: () => ({
    selectedCategory: null,
    categoryId: 0,
    venueServiceId: null,
    productCategories: [
      {
        title: "",
        category_id: null,
      },
    ],
    confirmModel: {
      id: null,
      title: null,
      description: null,
    },
    openTabs: [0],
    categoryDialog: false,
    valid: false,
    timingConfiguration: [
      { name: null, days: [], to_time: null, from_time: null },
    ],

    timeData: constants.TIMINGS,
    productDialog: false,
    product: {},
    productData: {},
    configurations: {},
    projectNumber: null,
    transactionType: null,
    isEnableDctErp: false,
  }),

  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then((res) => {
        if (res.length) {
          this.checkVenueService();
        }
      });
    } else {
      this.checkVenueService();
    }
    if (this.$store.getters.venueInfo) {
      if (this.$store.getters.venueInfo.enable_dct_erp) {
        this.isEnableDctErp = true;
        if (this.$store.getters.venueInfo.dct_erp_configuration) {
          this.projectNumber =
            this.$store.getters.venueInfo.dct_erp_configuration.project_no;
          this.transactionType =
            this.$store.getters.venueInfo.dct_erp_configuration.transaction_type;
        }
      } else {
        this.isEnableDctErp = false;
      }
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getSportsService;
    },
    serviceName() {
      let service = this.venueServices.find(
        (item) => item.venue_service_id == this.venueServiceId
      );
      return service ? service.name : "";
    },
  },
  methods: {
    serviceChange() {
      this.getProductAndCategory();
      this.initializeFacilityServices();
    },
    checkVenueService() {
      if (this.$route.params.data != null) {
        let data = JSON.parse(atob(this.$route.params.data));
        this.venueServiceId = data.venue_service_id;
      } else {
        this.venueServiceId =
          this.$store.getters.getSportsService[0].venue_service_id;
      }
      setTimeout(() => {
        this.getProductAndCategory();
        this.initializeFacilityServices();
      }, 10);
    },
    initializeFacilityServices() {
      if (!this.$store.getters.getConfigurationStatus(this.venueServiceId)) {
        this.$store
          .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
          .then((response) => {
            if (response.status == 200) {
              let data = this.$store.getters.getConfigurationByVenueServiceId(
                this.venueServiceId
              );
              this.configurations = data;
              this.$forceUpdate();
            }
          });
      } else {
        let data = this.$store.getters.getConfigurationByVenueServiceId(
          this.venueServiceId
        );
        this.configurations = data;
        this.$forceUpdate();
      }
      this.$forceUpdate();
    },

    addProduct(selectedCategory) {
      this.productData.category_id = selectedCategory;
      this.productData.tax_type_id = this.configurations.default_tax_id;
      this.productDialog = true;
    },

    editProduct(data) {
      const productData =
        this.productCategories[data.cindex].products[data.index];
      this.productData.id = productData.product_id;
      this.productData.name = productData.name;
      this.productData.description = productData.description;
      this.productData.ar_name = productData.ar_name;
      this.productData.ar_description = productData.ar_description;
      this.productData.tax_type_id = productData.tax_type_id;
      this.productData.category_id = productData.category_id;
      this.productData.price = productData.price;
      this.productData.benefit_excluded = productData.benefit_excluded;
      this.productData.enable_online_booking =
        productData.enable_online_booking;
      this.productData.image_path = productData.image;
      this.productData.total_price = productData.total_price;
      this.productData.inventory_enable = productData.inventory_enable;
      this.productData.ticket_type = productData.ticket_type;
      this.productData.participant_count = productData.participant_count;
      if (this.isEnableDctErp) {
        this.productData.task_name = productData.task_name;
        this.productData.gl_code = productData.gl_code;
      }

      if (productData.inventory) {
        this.productData.inventory_period =
          productData.inventory.inventory_period;
        this.productData.quantity = productData.inventory.quantity;
        if (productData.inventory.rules.length) {
          this.productData.is_enable_specific_day = 1;
          this.productData.rules = productData.inventory.rules;
        }
      }

      setTimeout(() => {
        this.productDialog = true;
      }, 10);
    },

    deleteProduct(productId) {
      // const searchProductById = this.productCategories
      //         .flatMap(category => category.products)
      //         .find(product => product.product_id === productId);
      this.confirmModel = {
        id: productId,
        title: "Do you want to delete this product?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete_product",
      };
    },

    confirmActions(data) {
      if (data.type == "delete_product") {
        this.$http
          .delete(
            `venues/products/${data.id}?category_id=${this.selectedCategory}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.productDialog = false;
              this.getProductAndCategory();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },

    getProductAndCategory() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/facilities/categories/${this.venueServiceId}`)
        .then((response) => {
          this.productCategories = [];
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.productCategories = response.data.data;
            if (this.productCategories && this.productCategories.length) {
              if(!this.selectedCategory){this.selectedCategory = this.productCategories[0].category_id;
            }
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    gotoFacilities() {
      this.$router.push({
        name: "FacilityPerService",
        params: {
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
        },
      });
    },

    gotoConfiguration() {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "FacilityConfiguration",
        params: {
          data: btoa(JSON.stringify({ venue_service_id: this.venueServiceId })),
        },
      });
    },
    productClose() {
      this.productDialog = false;
      this.productData = {};
    },
    productSave() {
      this.productDialog = false;
      this.productData = {};
      this.getProductAndCategory();
    },
    deleteCategory(index) {
      this.productCategories.splice(index, 1);
      if (!this.productCategories.length) {
        this.productCategories = [];
      }
    },
    gotoRentalTicketProducts(currentSlide) {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "TickerRentalProductPerService",
        params: {
          data: btoa(
            JSON.stringify({
              venue_service_id: this.venueServiceId,
              is_ticket: currentSlide == "ticket" ? 1 : 0,
            })
          ),
        },
      });
    },
  },
};
</script>
<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.v-expansion-panel-header--active {
  color: #00b0af;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}

.month_names {
  background-color: rgb(1, 78, 97);
  padding: 6px 8px;
  border-radius: 5px;
  color: white;
}

.v-expansion-panel-header--active .month_names {
  background-color: rgb(4, 148, 184);
}

.tab_is_active {
  color: #112a46;
  font-weight: 600;

  svg {
    opacity: 1 !important;
  }
}

.q-tab-nav {
  svg {
    fill: none !important;
    stroke: black !important;
    opacity: 0.4;
    min-width: 20px;
  }
}

.active_category {
  background-color: rgba(79, 174, 175, 0.1) !important;
  color: rgba(79, 174, 175, 1) !important;
  border: 1px solid rgba(79, 174, 175, 1) !important;
}
</style>
